exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-albums-js": () => import("./../../../src/pages/albums.js" /* webpackChunkName: "component---src-pages-albums-js" */),
  "component---src-pages-artists-js": () => import("./../../../src/pages/artists.js" /* webpackChunkName: "component---src-pages-artists-js" */),
  "component---src-pages-devotional-js": () => import("./../../../src/pages/devotional.js" /* webpackChunkName: "component---src-pages-devotional-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-language-page-js": () => import("./../../../src/templates/language-page.js" /* webpackChunkName: "component---src-templates-language-page-js" */),
  "component---src-templates-single-album-page-js": () => import("./../../../src/templates/single-album-page.js" /* webpackChunkName: "component---src-templates-single-album-page-js" */),
  "component---src-templates-song-page-js": () => import("./../../../src/templates/song-page.js" /* webpackChunkName: "component---src-templates-song-page-js" */),
  "component---src-templates-year-albums-js": () => import("./../../../src/templates/year-albums.js" /* webpackChunkName: "component---src-templates-year-albums-js" */)
}

